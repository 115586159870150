ko.components.register('standardupload', {
    viewModel: function (params) {
        var model = this;
        model.uploadType = params.uploadType ? ko.unwrap(params.uploadType) : 'file';
        model.uploadUrl = ko.unwrap(params.uploadUrl);
        
        model.PendingUploads = ko.observableArray();
        model.UploadedFile = params.uploadedFile || function (data) {
            console.log('File uploaded: ' + data.filename);
        };

        model.currentlyUploading = ko.computed(function () {
            return model.PendingUploads().length > 0;
        });

        model.overallPercentage = ko.observable(0);

        return model;
    },
    template: { fromUrl: 'standard-template.html' }
});

