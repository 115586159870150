$(function(){
    $('body').on('click', '.js-play-video', function () {
        
        var rawData = $(this).data('videodata');
        if (rawData.length !== 0) {
            if (typeof rawData !== 'string') {
                Elitehub.Videos.Show(JSON.stringify(rawData));
            } else {
                var clips = '{ clips : [' + rawData + ']}';
                Elitehub.Videos.ShowFromIds(clips);
            }

        }
    });

    $('body').on('click', '.js-play-video-ids', function () {

        var rawData = '{ clips : [' + $(this).data('videodata') + ']}';
        if (rawData.length !== 0) {
            Elitehub.Videos.ShowFromIds(rawData);
        }
    });

    $('body').on('click', '.js-play-fixture', function (e) {
        e.preventDefault();
        var id = $(this).data('fixtureid');
        Elitehub.Videos.ShowFixture(id);
    });

    $('body').on('click', '.js-manifesturl-video', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        Elitehub.Videos.ShowFromManifestUrl(url);
    });
});

Elitehub = Elitehub || {};
Elitehub.Videos = Elitehub.Videos || {};

Elitehub.Videos.ShowFromManifestUrl = function (url) {
    Elitehub.Utility.showLoadingPanel();
    $.ajax({
        url: url,
        type: 'POST',
        contentType: 'application/json, charset=utf-8'
    })
    .done(function (data) {
        Elitehub.Utility.hideLoadingPanel();

        if (data == '') {
            Elitehub.Toastr.Warning("No Clips Found", 0);
        } else {
            var href = '/video/play?playlist=' + data + '&lightcase=true';
            var w = $(this).data("width") || 656;
            var h = $(this).data("height") || 369;

            var viewport = $(window).width();

            if (viewport < w) {
                var videoWidth = viewport - 30;
                w = videoWidth;
                h = Math.floor((videoWidth * 0.5625));
            }

            href = href + "&width=" + w;

            lightcase.start({
                href: href,
                type: 'iframe',
                iframe: {
                    width: w,
                    height: h + 34,
                    frameborder: 0
                }
                // more options like width, height, etc.
            });
        }
    });
}

Elitehub.Videos.Show = function (data) {
    Elitehub.Utility.showLoadingPanel();
    var json = data;
    $.ajax({
        url: '/Video/GetManifestIdFromBasicData',
        data: json,
        type: 'POST',
        contentType: 'application/json, charset=utf-8'
    })
    .done(function (data) {
        Elitehub.Utility.hideLoadingPanel();

        var href = '/video/play?playlist=' + data + '&lightcase=true';
        var w = $(this).data("width") || 656;
        var h = $(this).data("height") || 369;

        var viewport = $(window).width();

        if (viewport < w) {
            var videoWidth = viewport - 30;
            w = videoWidth;
            h = Math.floor((videoWidth * 0.5625));
        }

        href = href + "&width=" + w;

        lightcase.start({
            href: href,
            type: 'iframe',
            iframe: {
                width: w,
                height: h + 34,
                frameborder: 0
            }
            // more options like width, height, etc.
        });
    });
}

Elitehub.Videos.ShowFromIds = function (data) {
    Elitehub.Utility.showLoadingPanel();
    var json = data;
    $.ajax({
        url: '/Video/GetManifestIdFromIds',
        data: json,
        type: 'POST',
        contentType: 'application/json, charset=utf-8'
    })
    .done(function (data) {
        Elitehub.Utility.hideLoadingPanel();

        var href = '/video/play?playlist=' + data + '&lightcase=true';
        var w = $(this).data("width") || 656;
        var h = $(this).data("height") || 369;

        var viewport = $(window).width();

        if (viewport < w) {
            var videoWidth = viewport - 30;
            w = videoWidth;
            h = Math.floor((videoWidth * 0.5625));
        }

        href = href + "&width=" + w;

        lightcase.start({
            href: href,
            type: 'iframe',
            iframe: {
                width: w,
                height: h + 34,
                frameborder: 0
            }
            // more options like width, height, etc.
        });
    });
}

Elitehub.Videos.ShowFixture = function (id) {

        var href = '/video/playfixture/' + id + '?lightcase=true';
        var w = $(this).data("width") || 656;
        var h = $(this).data("height") || 369;

        var viewport = $(window).width();

        if (viewport < w) {
            var videoWidth = viewport - 30;
            w = videoWidth;
            h = Math.floor((videoWidth * 0.5625));
        }

        href = href + "&width=" + w;

        lightcase.start({
            href: href,
            type: 'iframe',
            iframe: {
                width: w,
                height: h + 34,
                frameborder: 0
            }
        });
}