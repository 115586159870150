ko.components.register('upload', {
    viewModel: function (params) {
        var model = this;
        model.uploadType = params.uploadType ? ko.unwrap(params.uploadType) : 'file';
        model.uploadUrl = ko.unwrap(params.config.UploadUrl);
        model.bucket = ko.unwrap(params.config.Bucket);
        model.key = ko.unwrap(params.config.Key);
        model.hiddenFields = ko.unwrap(params.config.HiddenFields);
        
        model.PendingUploads = ko.observableArray();
        model.UploadedFile = params.uploadedFile || function (data) {
            console.log('File uploaded: ' + data.filename);
        };

        model.currentlyUploading = ko.computed(function () {
            return model.PendingUploads().length > 0;
        });

        model.getKey = function (filename) {
            var _filename = params.getFilename ? params.getFilename(filename) : Elitehub.Utility.convertToSlug(filename) + '_' + Elitehub.Utility.getGuid();
            return model.key.replace('${filename}', _filename);
        };

        model.overallPercentage = ko.observable(0);

        return model;
    },
    template: { fromUrl: 'template.html' }
});

