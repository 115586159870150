Elitehub.Select2 = (function () {

    var _formatResult = function (result) {
        if (result.loading) {
            return result.text;
        }

        var markup = '<span>%(text)s</span>';

        var output = s.sprintf(markup, result);

        return output;
    };

    var _formatResultImageSub = function (result) {
        if (result.loading) {
            return result.text;
        }

        if (!result.id) return result.text; // optgroup

        var markup = '<img class="select2-result-main-image" loading="lazy" src="%(image)s" /><span class="select2-result-text">%(text)s</span>';

        var output = s.sprintf(markup, result);

        return output;
    };

    var _formatResultImageWithSub = function (result) {
        if (result.loading) {
            return result.text;
        }

        if (!result.image) {
            result.image = "/gradient.png?width=30&height=30&color1=cccccc&color2=cccccc";
        }

        var markup = '<img class="select2-result-main-image" loading="lazy" src="%(image)s" /><span class="select2-result-text">%(text)s</span>';
        if (result.description) {
            markup = '<img class="select2-result-main-image" loading="lazy" src="%(image)s" /><p class="select2-result-title-text">%(text)s</p><p class="select2-result-sub-text text-muted">%(description)s</p>';
        }

        var output = s.sprintf(markup, result);

        return output;
    };

    var _formatResultWithSub = function (result) {
        if (result.loading) {
            return result.text;
        }

        var markup = '';
        if (result.image) {
            markup = '<img class="select2-result-main-image" loading="lazy" src="%(image)s" /><p class="select2-result-title-text">%(text)s</p><p class="select2-result-sub-text text-muted">%(description)s</p>';
        } else {
            markup = '<div class="tw-font-bold tw-mt-[2px] tw-mb-[5px] tw-ml-[5px] tw-leading-5">%(text)s</div><div class="tw-mb-[5px] tw-ml-[5px] text-muted tw-leading-5">%(description)s</div>';
        }

        var output = s.sprintf(markup, result);

        return output;
    };

    var _formatResultForPlayer = function (result) {
        if (result.loading) {
            return result.text;
        }
        if (!result.id) return result.text; // optgroup

        result.club = result.club || '';
        result.dob = result.dob || '';

        var markup = '<img class="select2-result-main-image img-circle" loading="lazy" src="%(photo)s" /><p class="select2-result-title-text">%(text)s</p><p class="select2-result-sub-text text-muted">%(club)s</p><p class="select2-result-sub-text text-muted">DOB: %(dob)s</p>';

        var output = s.sprintf(markup, result);

        return output;

        //return "<img class='item-image' src='" + state.photo + "' /><span class='select2-title-text'>" + state.text + "</span><br /><span class='select2-sub-text'>" + (state.club !== null ? state.club : '') + "</span>";

    }

    var _formatSelection = function (state) {
        if (!state.id) return state.text; // optgroup
        var img = state.image || state.photo || ko.dataFor(state.element).image();
        return state.text + "<img class='item-image img-circle' loading='lazy' src='" + img + "' />";
    }

    return {
        formatResult: _formatResult,
        formatResultImageSub: _formatResultImageSub,
        formatResultImageWithSub: _formatResultImageWithSub,
        formatResultForPlayer: _formatResultForPlayer,
        formatSelection: _formatSelection,
        formatResultWithSub: _formatResultWithSub
    };

})();