ko.bindingHandlers.fileupload = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {

        var el = $(element);
        el.fileupload({
            dataType: 'xml',
            fileInput: el.find('input:file'),
            dropZone: el,
            url: viewModel.uploadUrl,
            start: function (e) { },
            add: function (e, data) {
                var upload = {};
                upload.Filename = ko.observable('');
                upload.percentage = ko.observable(0);
                viewModel.PendingUploads.unshift(upload);
                data.context = upload;

                var file = data.files[0];
                var input = el.find('#key');
                var key = viewModel.getKey(file.name);
                data.context.key = key;
                input.val(key);

                data.submit();
            },
            send: function (e, data) {
                data.context.Filename(data.files[0].name);
                if (data.dataType &&
                        data.dataType.substr(0, 6) === 'iframe') {
                    // Iframe Transport does not support progress events.
                    // In lack of an indeterminate progress bar, we set
                    // the progress to 100%, showing the full animated bar:
                    el.find('.progress').addClass('progress-animated');
                        
                    viewModel.overallPercentage(100);
                }
                return true;
            },
            stop: function (e) { },
            progress: function (e, data) {
                data.context.percentage(parseInt(data.loaded / data.total * 100, 10));
            },
            progressall: function (e, data) {
                viewModel.overallPercentage(parseInt(data.loaded / data.total * 100, 10));
            },
            done: function (e, data) {
                var bucket = viewModel.bucket;
                var filename = data.context.Filename();
                var key = data.context.key;

                var content = {};
                content.bucket = bucket;
                content.key = key;
                content.filename = filename;

                viewModel.UploadedFile(content);
            },
            always: function (e, data) {
                bindingContext.$data.PendingUploads.remove(data.context);
            },
            fail: function (e, data) {
                Elitehub.Toastr.Error('Error: ' + data.errorThrown, 0);
            }
        });
    }
};

ko.bindingHandlers.standardfileupload = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {

        var el = $(element);
        el.fileupload({
            dataType: 'json',
            fileInput: el.find('input:file'),
            dropZone: el,
            url: viewModel.uploadUrl,
            start: function (e) { },
            add: function (e, data) {
                var upload = {};
                upload.Filename = ko.observable('');
                upload.percentage = ko.observable(0);
                viewModel.PendingUploads.unshift(upload);
                data.context = upload;

                var file = data.files[0];
                var input = el.find('#key');
                var key = file.name;
                data.context.key = key;
                input.val(key);

                data.submit();
            },
            send: function (e, data) {
                data.context.Filename(data.files[0].name);
                if (data.dataType &&
                        data.dataType.substr(0, 6) === 'iframe') {
                    // Iframe Transport does not support progress events.
                    // In lack of an indeterminate progress bar, we set
                    // the progress to 100%, showing the full animated bar:
                    el.find('.progress').addClass('progress-animated');

                    viewModel.overallPercentage(100);
                }
                return true;
            },
            stop: function (e) { },
            progress: function (e, data) {
                data.context.percentage(parseInt(data.loaded / data.total * 100, 10));
            },
            progressall: function (e, data) {
                viewModel.overallPercentage(parseInt(data.loaded / data.total * 100, 10));
            },
            done: function (e, data) {
                viewModel.UploadedFile(data.result);
            },
            always: function (e, data) {
                bindingContext.$data.PendingUploads.remove(data.context);
            },
            fail: function (e, data) {
                Elitehub.Toastr.Error('Error: ' + data.errorThrown, 0);
            }
        });
    }
};

ko.bindingHandlers.profanddevfileupload = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {

        var el = $(element);
        el.fileupload({
            dataType: 'xml',
            fileInput: el.find('input:file'),
            dropZone: el,
            url: el.data("uploadurl"),
            start: function (e) {
                el.find('.progress').show();
                el.find('.progress').addClass('in');
            },
            add: function (e, data) {
                var upload = {};
                upload.Filename = ko.observable('');
                upload.percentage = ko.observable(0);
                bindingContext.$data.PendingUploads.unshift(upload);
                data.context = upload;

                var file = data.files[0];
                var input = el.find('#key');
                var key = input.data('template').replace('${filename}', Elitehub.Utility.convertToSlug(file.name) + '_' + Elitehub.Utility.getGuid());
                data.context.key = key;
                input.val(key);

                data.submit();
            },
            send: function (e, data) {
                data.context.Filename(data.files[0].name);
                if (data.dataType &&
                        data.dataType.substr(0, 6) === 'iframe') {
                    // Iframe Transport does not support progress events.
                    // In lack of an indeterminate progress bar, we set
                    // the progress to 100%, showing the full animated bar:
                    el.find('.progress').addClass(
                            !$.support.transition && 'progress-animated'
                        )
                        .find('.bar').css(
                            'width',
                            parseInt(100, 10) + '%'
                        );
                }
                return true;
            },
            stop: function (e) {
                el.find('.bar').css('width', '0%');
                el.find('.progress').removeClass('in');
                el.find('.progress').hide();
            },
            progress: function (e, data) {
                data.context.percentage(parseInt(data.loaded / data.total * 100, 10));
            },
            progressall: function (e, data) {
                el.find('.progress .bar').css(
                    'width',
                    parseInt(data.loaded / data.total * 100, 10) + '%'
                );
            },
            done: function (e, data) {
                var bucket = el.data('bucket');
                var filename = data.context.Filename();
                var key = data.context.key;
                var url = el.data("addurl");
                var content = {};
                content.bucket = bucket;
                content.key = key;
                content.filename = filename;

                $.post(url, content, function (result, statusText, xhr) {
                    $.each(result, function (index, file) {

                        var mapping = {
                            key: function (data) {
                                return ko.utils.unwrapObservable(data.Id);
                            },
                            create: function (options) {
                                return new Elitehub.ProfilingAndDevelopment.AttachmentModel(options.data, options.parent);
                            }
                        }

                        bindingContext.$data.Attachments.unshift(ko.mapping.fromJS(file, mapping));
                    });
                });

            },
            always: function (e, data) {
                bindingContext.$data.PendingUploads.remove(data.context);
            },
            fail: function (e, data) {
                Elitehub.Toastr.Error('Error: ' + data.errorThrown, 0);
            }
        });
    }
};