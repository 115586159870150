$(function () {
    document.addEventListener("trix-attachment-add", function (event) {
        var attachment = event.attachment;
        if (attachment.file) {
            return uploadAttachment(attachment);
        }
    });

    createStorageKey = function (filename) {
        var date, day, time;
        date = new Date;
        day = date.toISOString().slice(0, 10);
        time = date.getTime();
        return "trix-uploads/" + day + "/" + time + "/" + filename;
    };

    function uploadAttachment(attachment) {

        var file = attachment.file;
        var actualFilename = file.name;
        var filename = Elitehub.Utility.getGuid() + '_' + Elitehub.Utility.getSafeFilename(actualFilename);
        var key = createStorageKey(filename);
        var bucket = 'elitehub-files';

        $.get('/other/upload/presignedurl',
            {
                bucket: bucket,
                key: key,
                filename: actualFilename
            },
            function (data) {
                // Create our form data to submit
                var form = new FormData;
                _.each(data, function (item, index, object) {
                    form.append(item.Key, item.Value);
                });
                //form.append("Content-Type", file.type);
                form.append("file", file, actualFilename);

                // Create our XHR request
                var xhr = new XMLHttpRequest;
                xhr.open("POST", 'https://' + bucket + '.s3.amazonaws.com/', true);

                // Report file uploads back to Trix
                xhr.upload.onprogress = function (event) {
                    var progress = event.loaded / event.total * 100;
                    attachment.setUploadProgress(progress);
                }

                // Tell Trix what url and href to use on successful upload
                xhr.onload = function () {
                    if (xhr.status === 201) {
                        //var data = JSON.parse(xhr.responseText);

                        return attachment.setAttributes({
                            url: '/s3/' + bucket + '/' + key + '?width=1280&404=/assets/no-preview.jpg',
                            href: '/other/upload/download?key=' + key + '&bucket=' + bucket + '&filename=' + actualFilename
                        })
                    }
                }

                xhr.send(form);
            }
        );

    }


    $('body').on('dragover dragenter', 'trix-editor', function (e) {
        $(this).addClass('is-dragover');
    });

    $('body').on('dragleave dragend drop', 'trix-editor', function (e) {
        $(this).removeClass('is-dragover');
    });

        
});