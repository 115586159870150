Elitehub.Utility = function () {

    var _escapeRegExp = function escapeRegExp(string) {
        return string.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    };

    var _replaceAll = function (string, find, replace) {
        return string.replace(new RegExp(_escapeRegExp(find), 'g'), replace);
    };

    var _parseDate = function (jsonDate) {
        var re = /-?\d+/;
        var m = re.exec(jsonDate);
        var d = new Date(parseInt(m[0]));
        var currDate = d.getDate();
        var currMonth = d.getMonth() + 1; //Months are zero based
        var currYear = d.getFullYear();
        return currMonth + '-' + currDate + '-' + currYear + ' ' + d.getHours() + ':' + d.getMinutes();
    };

    var _getParameterByName = function getParameterByName(name) {

        var match = RegExp('[?&]' + name + '=([^&]*)')
                        .exec(window.location.search);

        return match ?
            decodeURIComponent(match[1].replace(/\+/g, ' '))
            : null;

    };
    
    var _getGuid = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    var _convertToSlug = function (text) {
        return (text
            .toLowerCase()
            .replace(/[^\w ]+/g, '')
            .replace(/ +/g, '-')).substring(0, 50);
    };

    var _getExtension = function (filename) {
        var a = filename.split('.');
        if (a.length === 1 || (a[0] === '' && a.length === 2)) {
            return '';
        }
        return '.' + a.pop().toLowerCase();
    };

    var _getSafeFilename = function (filename) {
        var lowered = filename.toLowerCase();
        var extension = _getExtension(lowered);
        var fname = lowered.substring(0, lowered.lastIndexOf('.'));
               
        return _convertToSlug(fname) + extension;
    };

    var _showLoadingPanel = function () {
        $('.loading-container').find('.loading-wrapper').show();
    };

    var _hideLoadingPanel = function () {
        clearTimeout(this.timer);
        $('.loading-container').find('.loading-wrapper').hide();
    };

    var _showLoadingPanelDelayed = function (delay) {
        if (delay === undefined) delay = 400;
        this.timer = setTimeout(function () {
            $('.loading-container').find('.loading-wrapper').show();
        }, delay, null);        
    };

    var _endBtnLoading = function (btn) {
        if (btn.hasClass('is-loading')) {
            btn.html(btn.data('original-loading'));
            btn.removeClass('is-loading disabled');
        }
    };

    return {
        parseDate: _parseDate,
        getParameterByName: _getParameterByName,
        getGuid: _getGuid,
        convertToSlug: _convertToSlug,
        getExtension: _getExtension,
        getSafeFilename: _getSafeFilename,
        replaceAll: _replaceAll,
        showLoadingPanel: _showLoadingPanel,
        hideLoadingPanel: _hideLoadingPanel,
        showLoadingPanelDelayed: _showLoadingPanelDelayed,
        endBtnLoading: _endBtnLoading
    };

}();

Elitehub.Utility.HandleResult = function (result) {
    if ($("#login", result).size() > 0) {
        window.location.reload();
    } else {
        if (!result.success) {
            Elitehub.Toastr.Clear();
            Elitehub.Toastr.Error(result.error, 0);
        }
    }
};