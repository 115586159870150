var Elitehub = Elitehub || {};

$(function () {
    $('html').on('click', '.row', function () {
        _.each(Highcharts.charts, function (el, i) {
            if (el != undefined) {
                if (el .tooltip != undefined) {
                    el.tooltip.hide();
                }
            }
        });
    });



});

$(function () {

    // select2 - hide selected items in results dropdown
    $.fn.select2.amd.require(['select2/selection/search'], function (Search) {
        var oldRemoveChoice = Search.prototype.searchRemoveChoice;

        Search.prototype.searchRemoveChoice = function () {
            oldRemoveChoice.apply(this, arguments);
            this.$search.val('');
        };
    });


    // select2 - select all items in optgroup
    $('body').on('click', '.select2-results__group', function (e, target) {
        var $this = $(this);
        $this.next('ul').find('li[aria-selected=false]').each(function (index, element) {
            $(element).trigger('mouseup');
        });
    });


    $(document).pjax('a[data-pjax]', '#pjax-container');

    $(document).on('pjax:click', function (options) { 
        console.log('pjax clicked');
    });

    $(document).on("pjax:timeout", function () { return false; });

    $(document).on('pjax:send', function () {
        Elitehub.Utility.showLoadingPanelDelayed();
    });

    $(document).on('pjax:complete', function () {
        Elitehub.Utility.hideLoadingPanel();

        /*
     * Easy Pie Charts - Used in widgets
     */
        function easyPieChart(id, trackColor, scaleColor, barColor, lineWidth, lineCap, size) {
            $('.' + id).easyPieChart({
                trackColor: trackColor,
                scaleColor: scaleColor,
                barColor: barColor,
                lineWidth: lineWidth,
                lineCap: lineCap,
                size: size
            });
        }

        /* Main Pie Chart */
        if ($('.main-pie')[0]) {
            easyPieChart('main-pie', 'rgba(255,255,255,0.2)', 'rgba(255,255,255,0.5)', 'rgba(255,255,255,0.7)', 7, 'butt', 148);
        }

        /* Others */
        if ($('.sub-pie-1')[0]) {
            easyPieChart('sub-pie-1', '#eee', '#ccc', '#4CAF50', 4, 'butt', 95);
        }

        if ($('.sub-pie-2')[0]) {
            easyPieChart('sub-pie-2', '#eee', '#ccc', '#F44336', 4, 'butt', 95);
        }
    });

    $(document).on('pjax:end', function () { 
        var target = $('div[data-spy="affix"]');
        target.affix({
            offset: { top: target.data('offset-top') }
        });
    });

    $('body').tooltip({
        selector: '[data-toggle="tooltip"]'
    });

    $('body').tooltip({
        selector: '[data-toggle="popover"]'
    });

    kendo.culture("en-GB"); //your culture

    // drag highlighting
    $('.filereader').bind('dragover', function (e) {
        var dropZone = $('.eh-uploader'),
            timeout = window.dropZoneTimeout;
        if (timeout) {
            clearTimeout(timeout);
        }

        var target = $(e.target);
        var zone = target.hasClass('eh-uploader') ?
            target : target.closest('.eh-uploader').length > 0 ?
            target.closest('.eh-uploader') : null;

        if (zone != null) {
            zone.addClass('hover');
        } else {
            dropZone.removeClass('hover');
        }
        window.dropZoneTimeout = setTimeout(function () {
            window.dropZoneTimeout = null;
            dropZone.removeClass('hover');
        }, 100);
    });

    // drag highlighting for old prof and dev section uploader
    $('.filereader').bind('dragover', function (e) {
        var dropZone = $('.attachments-wrapper'),
            timeout = window.dropZoneTimeout;
        if (timeout) {
            clearTimeout(timeout);
        }

        var target = $(e.target);
        var zone = target.hasClass('attachments-wrapper') ?
            target : target.closest('.attachments-wrapper').length > 0 ?
            target.closest('.attachments-wrapper') : null;

        if (zone != null) {
            zone.addClass('hover');
        } else {
            dropZone.removeClass('hover');
        }
        window.dropZoneTimeout = setTimeout(function () {
            window.dropZoneTimeout = null;
            dropZone.removeClass('hover');
        }, 100);
    });

    

});
