$(function() {
    $(document).on('click', '.js-btn-loading', function () {
        var el = $(this);
        if (el.hasClass('disabled')) {
            return false;
        }

        el.data('original-loading', el.html());

        el.addClass('btn-loading is-loading disabled');

        var i = el.find('i');

        if (i.length > 0) {
            i.addClass('btn-loading-icon');
            i.after('<i class="fa fa-spin fa-spinner btn-loading-icon-spinner"></i>');
        } else {
            el
                .wrapInner('<span class="btn-loading-label"></span>')
                .append('<span class="btn-loading-spinner"><i class="fa fa-spinner fa-spin btn-loading-icon-spinner"></i></span>');
        }
    });

    $(document).on('click', '[data-loading="true"]', function () {
        Elitehub.Utility.showLoadingPanel();
    });
}); 