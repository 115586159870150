$(function () {
    $('form').on('change', '.auto-submit', function (e) {
        e.preventDefault();
        
        var form = $(this).closest('form');

        if (form.hasClass('js-show-loading')) {
            Elitehub.Utility.showLoadingPanel();
        }

        form.submit();
    });

    $('form.js-show-loading').on('submit', function (e) {
        Elitehub.Utility.showLoadingPanel();
    });
});

jQuery(function ($) {
    $.validator.methods['date'] =  function (value, element) {
        return this.optional(element) || new moment('14/09/2017', 'DD/MM/YYYY').isValid();
    };
});
