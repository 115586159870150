Elitehub = Elitehub || {};


// 🌓Radial Gradient
Elitehub.GetHighchartsGradientColor = function (color) {
    return {
        radialGradient: { cx: 0.5, cy: 0.3, r: 0.7 },
        stops: [
            [0, color],
            [1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
        ]
    };
};

// Linear Gradient
Elitehub.GetHighchartsLinearGradientColor = function (color) {
    return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
            [0, color],
            [1, Highcharts.Color(color).brighten(-0.2).get('rgb')] // darken
        ]
    };
};

// Set Radial Gradient
Elitehub.SetRadialColors = function () {
    // Radialize the colors
    Highcharts.getOptions().colors = Highcharts.map(highcharts_original_colors, function (color) {
        return {
            radialGradient: {
                cx: 0.5,
                cy: 0.3,
                r: 0.7
            },
            stops: [
                [0, color],
                [1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
            ]
        };
    });
};

// Linear Gradient
Elitehub.SetLinearColors = function () {
    // Radialize the colors
    Highcharts.getOptions().colors = Highcharts.map(highcharts_original_colors, function (color) {
        return {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
                [0, color],
                [1, Highcharts.Color(color).brighten(-0.2).get('rgb')] // darken
            ]
        };
    });
};

$(function () {

    Highcharts.wrap(Highcharts.Axis.prototype, 'getPlotLinePath', function (proceed) {
        var path = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        if (path) {
            path.flat = false;
        }
        return path;
    });
    
    Highcharts.setOptions({
        tooltip: {
            useHTML: true,
            backgroundColor: 'white',
            borderRadius: 3,
            borderWidth: 1
        },
        chart: {
            exporting: {
                enabled: true
            }
        },
        exporting: {
            enabled: false
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        }
    });



});