Elitehub = Elitehub || {};
Elitehub.Charts = Elitehub.Charts || {};

Elitehub.Charts.Pie = function (element, series, title, options) {
    element.highcharts($.extend(true, {
        series: series,
        exporting: { enabled: false },
        chart: {
            type: 'pie',
            borderWidth: 0,
            borderColor: '#ececec',
            backgroundColor: 'white'
        },
        title: {
            text: title
        },
        yAxis: {
            allowDecimals: false,
            title: {
                text: null
            }
        },
        xAxis: {
            labels: {
                style: {
                    whiteSpace: 'nowrap'
                }
            }
        },
        legend: {
            enabled: true,
            margin: 1
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                showInLegend: true,
                dataLabels: {
                    useHTML: false,
                    enabled: true,
                    //format: '{point.name}<br/>{point.percentage:.0f}% ({y})',
                    formatter: function () {
                        if (this.y !== 0) {
                            return this.point.name + '<br/>' + this.point.percentage.toFixed() + '% (' + this.y + ')';
                        } else {
                            return null;
                        }
                    },
                    distance: 15,
                    style: {
                        "color": "contrast",
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "textShadow": "0 0 6px contrast, 0 0 3px contrast",
                        "lineHeight": "11px",
                        "textAlign": 'center'
                    }
                },
                events: {
                    click: function (event) {
                        var point = event.point;
                        if (point.data) {
                            var videodata = point.data;
                            Elitehub.Videos.Show(videodata);
                        }
                    }
                }
            }
        },
        tooltip: {
            formatter: function () {
                return '<b>' + this.point.name + '</b>:' + this.point.percentage.toFixed(1) + ' %';
            }
        }
    }, options));
};

Elitehub.Charts.Donut = function (element, series, title, subtitle) {
    element.highcharts({
        series: series,
        exporting: { enabled: false },
        chart: {
            type: 'pie',
            borderWidth: 0,
            borderColor: '#ececec',
            backgroundColor: 'white'
        },
        title: {
            text: title,
            align: 'center',
            verticalAlign: 'middle',
            y: -20
        },
        subtitle: {
            text: subtitle,
            align: 'center',
            verticalAlign: 'middle',
            y: 0
        },
        yAxis: {
            allowDecimals: false,
            title: {
                text: null
            }
        },
        xAxis: {
            labels: {
                style: {
                    whiteSpace: 'nowrap'
                }
            }
        },
        legend: {
            enabled: true,
            margin: 1,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,
                center: ['50%', '50%'],
                //dataLabels: {
                //    useHTML: true,
                //    enabled: true,
                //    formatter: function() {
                //        if (this.y != 0) {
                //            return '<span style="color:' + this.point.color + '">' + this.point.name + '<br /><span style="font-weight: normal">' + this.point.percentage.toFixed('0') + '% (' + this.y + ')</span></span>';
                //        } else {
                //            return null;
                //        }
                //    },
                //    color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || '#3c3c3b'
                //},
                events: {
                    click: function (event) {
                        var point = event.point;
                        if (point.data) {
                            var videodata = point.data;
                            Elitehub.Videos.Show(videodata);
                        }
                    }
                }
            }
        },
        tooltip: {
            formatter: function () {
                return '<b>' + this.point.name + '</b>:' + this.point.percentage.toFixed(1) + ' %';
            }
        }
    });
};