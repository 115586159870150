$(function () {
    
    // Get images of class lazy
    var images = document.querySelectorAll('img.lazy');
    var config = {
        // If image gets within 50px go get it
        rootMargin: '50px 0px',
        threshold: 0.01
    };

    var observer = new IntersectionObserver(onIntersection, config);
    _.forEach(images, function (image) {
        observer.observe(image);
    });

    function onIntersection(entries) {
        // Loop through the entries
        _.forEach(entries, function (entry) {
            // Are we in viewport?
            if (entry.intersectionRatio > 0) {

                // Stop watching and load the image
                observer.unobserve(entry.target);
                preloadImage(entry.target);
            }
        });
    }

    function preloadImage(element) {
        try {
            var el = $(element);
            el.attr('src', el.data('src'));
        } catch (e) {
            console.log('error loading image...');
            console.log(e);
        }
    }
});