var Elitehub = Elitehub || {};

Elitehub.Toastr = {
    timer: null,
    nextMessage: '',
    Init: function () {
        toastr.options.fadeOut = 300;
        toastr.options.timeOut = 3000;
    },
    Info: function (message, delay) {
        if (delay === undefined) {
            delay = 400;
        }
        this.timer = setTimeout(function () {
            toastr.info(message);
        }, delay, null);
    },
    Warning: function (message, delay) {
        if (delay === undefined) {
            delay = 400;
        }
        this.timer = setTimeout(function () {
            toastr.warning(message);
        }, delay, null);
    },
    Success: function (message, delay) {
        if (delay === undefined) {
            delay = 400;
        }
        this.timer = setTimeout(function () {
            toastr.success(message);
        }, delay, null);
    },
    Error: function (message, delay, timeout) {
        if (delay === undefined) {
            delay = 400;
        }
        this.timer = setTimeout(function () {
            if (timeout !== undefined) {
                toastr.error(message, null, { timeOut: timeout });
            } else {
                toastr.error(message);
            }
        }, delay, null);
    },
    Clear: function () {
        clearTimeout(this.timer);
        toastr.clear();
    },
    Hide: function () {
        clearTimeout(this.timer);
        toastr.options.fadeOut = 0;
        toastr.clear();
        toastr.options.fadeOut = 300;
    },
    SuccessFromNext: function () {
        if (this.nextMessage !== '') {
            toastr.success(this.nextMessage);
            this.nextMessage = '';
        }
    },

    Loading: function (message, delay) {
        if (delay === undefined) {
            delay = 400;
        }
        this.timer = setTimeout(function () {
            toastr.info(message, null, { timeOut: 0 });
        }, delay, null);
    },

    HandleResult: function (result, successMessage, timeout) {
        Elitehub.Toastr.Clear();
        if (!result.success) {
            Elitehub.Toastr.Error(result.error, 0, timeout);
        } else {
            Elitehub.Toastr.Success(successMessage, 0);
        }
    }

};
