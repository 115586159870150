$(function () {
    $('body').on('click', '.defaultrangepicker', function () {

        var picker = $(this);
        var input = picker.prev('input');

        var ranges = picker.data('ranges');

        function cb(start, end, label) {
            if (label != "Custom Range" && !label.startsWith('-')) {                
                input.val(Elitehub.Utility.replaceAll(label, ' ', '').toLowerCase());
                picker.find('span').html(label);                
            } else {
                picker.find('span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
                input.val(start.format('YYYY/MM/DD') + '-' + end.format('YYYY/MM/DD'));
            }
            input.trigger('change');
        }
        //cb(moment().subtract(29, 'days'), moment());

        function GetDateRanges() {
            var start = 0;
            var year = (new Date()).getFullYear();
            if (new Date() <= new Date(year, 6, 30)) {
                start = year - 1;
            } else {
                start = year;
            }

            var ranges = {};
            ranges['CURRENT SEASON'] = [start + '/8/1', start + 1 + '/7/31'];


            for (var i = 1; i <= 5; i++) {
                var x = start - i;
                ranges[' ' + x + '/' + (x + 1)] = [x + '/8/1', x + 1 + '/7/31'];
            }
            ranges['CURRENT YEAR'] = [year + '/1/1', year + '/12/31'];

            for (var i = 1; i <= 5; i++) {
                var x = year - i;
                ranges[' ' + x] = [x + '/1/1', x + '/12/31'];
            }
            ranges['- 19/20 Extended'] = ['2019/7/1', '2020/11/01'];
            ranges['- Gallagher 20/21'] = ['2020/11/15', '2021/6/30'];
            ranges['- Allianz 20/21'] = ['2020/10/1', '2021/6/30'];

            ranges['ALL DATES'] = ['1900/1/1', '2100/12/31'];
            return ranges;
        }

        picker.daterangepicker({
            autoUpdateInput: false,
            format: 'YYYY/MM/DD',
            opens: picker.data('opens'),
            alwaysShowCalendars: picker.data('alwaysshowcalendars'),
            locale: {
                "format": "YYYY/MM/DD",
                "separator": " - "
            },
            startDate: picker.data('start'), 
            endDate: picker.data('end'), 
            ranges: ranges ? ranges : GetDateRanges()
        }, cb);

        $(this).removeClass('defaultrangepicker').click();
    });
});