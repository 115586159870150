
// jQuery Plugin Boilerplate
// A boilerplate for jumpstarting jQuery plugins development
// version 2.0, July 8th, 2011
// by Stefan Gabos

// remember to change every instance of "pluginName" to the name of your plugin!
// the semicolon at the beginning is there on purpose in order to protect the integrity 
// of your scripts when mixed with incomplete objects, arrays, etc.
; (function ($) {

    $.windowPopup = function (el, options) {

        var defaults = {

            width: "95%",
            maxWidth: "730px",

            onClose: function () { },

        }

        var scrollPos = 0;

        var plugin = this;

        // this will hold the merged default, and user-provided options
        // plugin's properties will be accessible like:
        // plugin.settings.propertyName from inside the plugin or
        // myplugin.settings.propertyName from outside the plugin
        // where "myplugin" is an instance of the plugin
        plugin.settings = {};
       
        var init = function () {

            plugin.settings = $.extend({}, defaults, options);

            plugin.el = el;

            // code goes here
            el.find('.js-close-window').on('click', function (e) {
                e.preventDefault();
                plugin.hide();
            });

            el.find('.window-overlay').on('click', function (e) {
                if (e.target == this) {
                    plugin.hide();
                }
            });

        }

        // public methods
        // these methods can be called like:
        // plugin.methodName(arg1, arg2, ... argn) from inside the plugin or
        // myplugin.publicMethod(arg1, arg2, ... argn) from outside the plugin
        // where "myplugin" is an instance of the plugin

        plugin.show = function () {
            scrollPos = window.scrollY; // save scroll position
            $('html').addClass('window-popup-visible');
            el.show();
        }

        plugin.hide = function () {
            $('html').removeClass('window-popup-visible');
            window.scrollTo(0, scrollPos); // set scroll position
            //$("html").getNiceScroll().onResize();
            el.hide();
            close();
        }

        // private methods
        // these methods can be called only from inside the plugin like:
        // methodName(arg1, arg2, ... argn)

        var close = function () {
            plugin.settings.onClose.call(this);
        }

        // call the "constructor" method
        init();

    }

})(jQuery);