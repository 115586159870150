$(function () {
    $('body').on('click', '.panel-material.js-expandable > .panel-heading', function (e) {
        var $this = $(this);
        var panel = $this.closest('.panel');
        var offset = panel.offset().top - $(window).scrollTop();
        if (panel.hasClass('active')) {
            panel.removeClass('active');
            panel.find('> .panel-body').addClass('hidden');
        } else {
            panel.trigger("panel-material:open");
            $this.closest('.material-panels-wrapper')
                .find('.panel-material')
                .removeClass('active')
                .find('> .panel-body')
                .addClass('hidden');

            panel.addClass('active');
            panel.find('> .panel-body').removeClass('hidden');

            $(window).scrollTop(panel.offset().top - offset - 40);
        }
    });
});