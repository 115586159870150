ko.bindingHandlers.numericValue = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var underlyingObservable = valueAccessor();
        var interceptor = ko.dependentObservable({
            read: underlyingObservable,
            write: function (value) {
                if ($.isNumeric(value)) {
                    underlyingObservable(parseFloat(value));
                } else {
                    underlyingObservable(0);
                    $(element).val(0);
                }
            }
        });
        ko.bindingHandlers.value.init(element, function () { return interceptor }, allBindingsAccessor);
    },
    update: ko.bindingHandlers.value.update
};

ko.bindingHandlers.viewTransition = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var shouldDisplay = valueAccessor();
        $(element).toggle(shouldDisplay);
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var shouldDisplay = valueAccessor(),
            allBindings = allBindingsAccessor(),
            duration = allBindings.animationDuraton || 400;

        var $view = $(element);
        if (shouldDisplay) {
            $view.fadeIn(duration);
        } else {
            $view.hide();
        }
    }
};

ko.bindingHandlers.loadingButton = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel,
                   bindingContext) {

        var name = ko.utils.unwrapObservable(valueAccessor)();
        bindingContext.$data[name] = ko.observable(false);

        $(element).addClass('btn-loading');

        ko.applyBindingsToNode(element, {
            css: {
                'is-loading disabled': bindingContext.$data[name]
            },
        });

        var i = $(element).find('i');

        if (i.length > 0) {
            i.addClass('btn-loading-icon');
            i.after('<i class="fa fa-spin fa-spinner btn-loading-icon-spinner"></i>');
        } else {
            $(element)
                .wrapInner('<span class="btn-loading-label"></span>')
                .append('<span class="btn-loading-spinner"><i class="fa fa-spinner fa-spin"></i></span>');
        }
                
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel,
                     bindingContext) {
    }
};

ko.bindingHandlers.setFocus = {
    update: function (element, valueAccessor, allBindings) {
        var value = valueAccessor();
        var $element = $(element);
        if (value()) {
            $element.focus();
        }

        var selectAllText = allBindings.get('selectAllText'); 
        if (selectAllText) {
            element.select();
        }
    }
};

ko.bindingHandlers.slideVisible = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var shouldDisplay = valueAccessor();
        $(element).toggle(shouldDisplay);
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var shouldDisplay = valueAccessor(),
            allBindings = allBindingsAccessor(),
            duration = allBindings.animationDuraton || 400;

        var $view = $(element);
        if (shouldDisplay) {
            $view.slideDown(duration);
        } else {
            $view.slideUp();
        }
    }
};

ko.bindingHandlers.fadeVisible = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var shouldDisplay = valueAccessor();
        $(element).toggle(shouldDisplay);
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var shouldDisplay = valueAccessor(),
            allBindings = allBindingsAccessor(),
            duration = allBindings.animationDuraton || 400;

        var $view = $(element);
        if (shouldDisplay) {
            $view.fadeIn(duration);
        } else {
            $view.fadeOut();
        }
    }
};

// Bind Bootstrap Popover
ko.bindingHandlers.popover = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var $element = $(element);
        var popoverBindingValues = ko.utils.unwrapObservable(valueAccessor());
        var template = popoverBindingValues.template || false;
        var options = popoverBindingValues.options || { title: 'popover' };
        var data = popoverBindingValues.data || false;
        if (template !== false) {
            if (data) {
                options.content = "<!-- ko template: { name: template, if: data, data: data } --><!-- /ko -->";
            }
            else {
                options.content = $('#' + template).html();
            }
            options.html = true;
        }
        $element.on('shown.bs.popover', function (event) {

            var popoverData = $(event.target).data();
            var popoverEl = popoverData['bs.popover'].$tip;
            var options = popoverData['bs.popover'].options || {};
            var button = $(event.target);
            var buttonPosition = button.position();
            var buttonDimensions = {
                x: button.outerWidth(),
                y: button.outerHeight()
            };

            ko.cleanNode(popoverEl[0]);
            if (data) {
                ko.applyBindings({ template: template, data: data }, popoverEl[0]);
            }
            else {
                ko.applyBindings(viewModel, popoverEl[0]);
            }

            var popoverDimensions = {
                x: popoverEl.outerWidth(),
                y: popoverEl.outerHeight()
            };

            popoverEl.find('button[data-dismiss="popover"]').click(function () {
                button.popover('hide');
            });

            switch (options.placement) {
                case 'right':
                    popoverEl.css({
                        left: buttonDimensions.x + buttonPosition.left,
                        top: (buttonDimensions.y / 2 + buttonPosition.top) - popoverDimensions.y / 2
                    });
                    break;
                case 'left':
                    popoverEl.css({
                        left: buttonPosition.left - popoverDimensions.x,
                        top: (buttonDimensions.y / 2 + buttonPosition.top) - popoverDimensions.y / 2
                    });
                    break;
                case 'top':
                    popoverEl.css({
                        left: buttonPosition.left + (buttonDimensions.x / 2 - popoverDimensions.x / 2),
                        top: buttonPosition.top - popoverDimensions.y
                    });
                    break;
                case 'bottom':
                    popoverEl.css({
                        left: buttonPosition.left + (buttonDimensions.x / 2 - popoverDimensions.x / 2),
                        top: buttonPosition.top + buttonDimensions.y
                    });
                    break;
            }
        });

        $element.popover(options);
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $element.popover('destroy');
        });

        return { controlsDescendantBindings: true };

    }
};

ko.bindingHandlers.select2 = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var obj = valueAccessor(),
            allBindings = allBindingsAccessor(),
            lookupKey = allBindings.lookupKey;

        $(element).select2(obj);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $(element).select2('destroy');
        });
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        var options = allBindingsAccessor().select2Options || {};

        for (var property in options) {
            $(element).select2(property, ko.utils.unwrapObservable(options[property]));
        }

        $(element).trigger('change');
    }
};

ko.bindingHandlers.autosizeTextArea = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        autosize($(element));
    },
    update: function (element) {
    }
};

ko.bindingHandlers.updateAutosizeTextArea = {
    update: function (element, valueAccessor, allBindings, vm, context) {
        autosize.update($(element));
    }
};

ko.bindingHandlers.koTrixEditor = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        $(element).append('<div class="trix-drop-target"><trix-editor input="' + ko.utils.unwrapObservable(valueAccessor()) + '" placeholder="' + $(element).data('placeholder') + '" autofocus></trix-editor></div>');
    },
    update: function (element) {
    }
};



